import { Auth, tauiUrl } from "../data/auth";
import { fetchWithAuth } from "./fetch-with-auth";
import { buildQueryString } from "../common/url/build_query_string";
import { TauiService, TauiServices } from "../types";

export const handleFetchPromise = async <T>(
  fetchPromise: Promise<Response>
): Promise<T> => {
  let response;

  try {
    response = await fetchPromise;
  } catch (err: any) {
    let errMessage = {
      error: "ui.api.http_error.request_error",
      status_code: 0,
      body: null,
    };

    // eslint-disable-next-line eqeqeq
    if (err.name == "AbortError") {
      errMessage = {
        error: "ui.api.http_error.request_aborted",
        status_code: 0,
        body: null,
      };
    } else {
      // eslint-disable-next-line no-console
      console.error(err);
    }

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw errMessage;
  }

  let body: any = null;

  const contentType = response.headers.get("content-type");

  if (contentType) {
    if (contentType.includes("application/json")) {
      try {
        body = await response.json();

        // X-AN-Count in body.hits
        if (
          body &&
          !Object.prototype.hasOwnProperty.call(body, "hits") &&
          response.headers.get("X-AN-Count")
        ) {
          // @ts-ignore
          body.hits = parseInt(response.headers.get("X-AN-Count"));
        }
      } catch (err: any) {
        // eslint-disable-next-line no-console
        console.error(err);

        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw {
          error: "ui.api.http_error.unable_parse_json_response",
          status_code: err.status,
          body: null,
        };
      }
    } else if (
      contentType.startsWith("image/") ||
      contentType === "application/octet-stream"
    ) {
      body = await response.blob();
    }
  } else {
    body = await response.text();
  }

  if (!response.ok) {
    // return body.error if exist and not null
    if (
      body &&
      Object.prototype.hasOwnProperty.call(body, "error") &&
      body.error !== null
    ) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw {
        error: "ui.api.http_error.request_error",
        status_code: response.status,
        body: body.error,
      };
    }

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw {
      error: "ui.api.http_error.request_error",
      status_code: response.status,
      body,
    };
  }

  return body as unknown as T;
};

export default async function tauiCallApi<T>(
  auth: Auth,
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH",
  service: TauiServices,
  path: string,
  parameters?: Record<string, unknown>,
  headers?: Record<string, string>
) {
  let url = `${auth.data.tauiUrl}/api/${service}${path}`;

  if (service === TauiService.grpc) url = `${tauiUrl}/${service}${path}`;

  const init: RequestInit = {
    method,
    headers: headers || {},
  };

  if (parameters) {
    // @ts-ignore
    init.headers["Content-Type"] = "application/json;charset=UTF-8";
    if (!["GET", "HEAD", "DELETE"].includes(method)) {
      if (parameters instanceof FormData || parameters instanceof File) {
        if (init.headers) delete init.headers["Content-Type"];

        init.body =
          parameters instanceof File ? new Blob([parameters]) : parameters;
      } else {
        init.body = JSON.stringify(parameters);
      }
    } else {
      if (!url.includes("?")) {
        url += "?";
      } else if (!url.endsWith("&")) {
        url += "&";
      }
      url += buildQueryString(parameters);
    }
  }

  return handleFetchPromise<T>(fetchWithAuth(auth, url, init));
}
